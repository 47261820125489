import React from 'react'
import ContentWrapper from '../components/ContentWrapper'

const ImpressView = (): JSX.Element => {
	return (
		<ContentWrapper p="1rem">
			<h1>Impressum</h1>

			<p>
				Beyer Development UG
				<br />
				Kolonnenstraße. 8
				<br />
				10827 Berlin
			</p>

			<p>
				Handelsregister: HRB 36746 P<br />
				Registergericht: 14467 Potsdam
			</p>

			<p>
				<strong>Vertreten durch:</strong>
				<br />
				Dr. Andreas Beyer
			</p>

			<h2>Kontakt</h2>
			<p>
				Telefon: 01629873949
				<br />
				E-Mail: andreas@beyer-development.com
			</p>

			<h2>EU-Streitschlichtung</h2>
			<p>
				Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
				bereit:{' '}
				<a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
					https://ec.europa.eu/consumers/odr/
				</a>
				.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
			</p>

			<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
			<p>
				Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
				Verbraucherschlichtungsstelle teilzunehmen.
			</p>
		</ContentWrapper>
	)
}

export default ImpressView
